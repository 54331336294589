<template>

    <div class="section categories">
        <div class="container-fluid">
            <div class="row center-row">
                <div class="col-12 d-lg-none">
                    <h4 class="title">Oder stöbere in unseren Kategorien:</h4>
                </div>
                <div v-for="(item,index) in categories" :key="index"
                class="col-12 col-md-6 col-xl-3 textInBox" :title="linkToTitle(item)">
                <kategorie-card :item="item" :color="item.color"/>
            </div>
        </div>
    </div>
</div>

</template>

<script>

  import { getContentsOfType } from '@/api/content';

  export default {
    name: "categorySection",
    components: {
        KategorieCard: () => import('@/components/cards/Kategorie.vue')
    },
    props: {
        category: {
            type: Object,
            default: null,
        }
    },
    data() {
        return {
            categories: [
            {
                image: '/assets/icons/hilfe.png',
                title: 'Beratung & Hilfe',
                link: '/category/Beratung & Hilfe',
                info: 'Finde Unterstützung in allen Lebenslagen.',
                color: 'meganta'
            },
            {
                image: '/assets/icons/freizeit.png',
                title: 'Freizeit',
                link: '/category/Freizeit',
                info: 'Finde Ideen für deine Freizeitgestaltung.',
                color: 'blue'
            },
            {
                image: '/assets/icons/beruf.png',
                title: 'Ausbildung & Weiterbildung',
                link: '/category/Ausbildung & Weiterbildung',
                info: 'Finde professionelle Ausbildungs- & Weiterbildungsangebote.',
                color: 'jade'
            },
            {
                image: '/assets/icons/kita.png',
                title: 'Kita- & Schulfinder',
                link: '/bildung',
                info: 'Informiere dich über Betreuungsmöglichkeiten und Schulen.',
                color: 'orange'
            }
            ],
        }
    },
    created() {
      this.getContentsForCategory();
  },
  computed: {
    cat(){
        if(this.category!==null){
            return this.category.name;
        }
        return null;
    },
    contents(){
        if(this.dataSet.data != null && this.dataSet.data.length>0){
            /*hard-coded Langcode*/
            return this.dataSet.data.length>0?this.dataSet.data:null;
        }
        return null;
    },
},   
methods: {
    async getContentsForCategory() {
        if(this.cat !== null){
            this.dataSet.query.keyword='"'+this.category.name+'"';
            const { limit, page } = this.dataSet.query;
            const { data, meta } = await getContentsOfType(this.$ratgeberId,this.dataSet.query);
            this.dataSet.data = data;
            this.dataSet.data.forEach((element, index) => {
              element['index'] = (page - 1) * limit + index + 1;
          });
            this.dataSet.meta = meta;
            this.dataSet.total = meta.total;
        }
    },
    linkToTitle(category){
      if(category.color !== "orange" ) {
        return "Alle Artikel zu dem Thema: "+category.title;
    }
    else {
        return "Alle Informationen zu dem: "+category.title;
    }
},
}
}
</script>

<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .textInBox {
        @media (min-width:992px) and (max-width:1320px) {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
</style>